import { CircleText } from "../../atoms/circle_text/circle_text";

interface Props {
  rotate?: boolean;
  text: React.ReactNode;
}

const ArrowIcon = () => (
  <svg width="100%" viewBox="0 0 101 139" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M97.2224 60.1012C69.1537 76.8506 52.048 121.796 50.4163 138.112L50.4157 81.4747C64.0943 64.9151 83.4558 60.1776 97.2224 60.1012Z"
      fill="currentColor"
    />
    <path
      d="M50.4163 138.112L49.5779 138.112L51.2506 138.196L50.4163 138.112ZM97.2224 60.1012L97.6521 60.8212L100.292 59.2456L97.2177 59.2627L97.2224 60.1012ZM50.4157 81.4747L49.7693 80.9407L49.5772 81.1732L49.5772 81.4747L50.4157 81.4747ZM51.2506 138.196C52.0523 130.179 56.69 114.969 64.6494 99.656C72.6093 84.3423 83.8133 69.0792 97.6521 60.8212L96.7928 59.3811C82.5628 67.8726 71.1795 83.4571 63.1615 98.8826C55.143 114.309 50.412 129.73 49.582 138.029L51.2506 138.196ZM97.2177 59.2627C83.2913 59.3401 63.655 64.1304 49.7693 80.9407L51.0621 82.0087C64.5336 65.6999 83.6202 61.0152 97.2271 60.9396L97.2177 59.2627ZM49.5772 81.4747L49.5779 138.112L51.2548 138.112L51.2542 81.4747L49.5772 81.4747Z"
      fill="currentColor"
    />
    <path
      d="M3.60877 60.1012C31.6775 76.8506 48.7832 121.796 50.4148 138.112L50.4155 81.4747C36.7369 64.9151 17.3754 60.1776 3.60877 60.1012Z"
      fill="currentColor"
    />
    <path
      d="M50.4148 138.112L51.2533 138.112L49.5805 138.196L50.4148 138.112ZM3.60877 60.1012L3.17912 60.8212L0.538849 59.2456L3.61343 59.2627L3.60877 60.1012ZM50.4155 81.4747L51.0619 80.9407L51.2539 81.1732L51.2539 81.4747L50.4155 81.4747ZM49.5805 138.196C48.7789 130.179 44.1412 114.969 36.1818 99.656C28.2218 84.3423 17.0179 69.0792 3.17912 60.8212L4.03842 59.3811C18.2684 67.8726 29.6516 83.4571 37.6697 98.8826C45.6882 114.309 50.4192 129.73 51.2491 138.029L49.5805 138.196ZM3.61343 59.2627C17.5398 59.3401 37.1762 64.1304 51.0619 80.9407L49.769 82.0087C36.2976 65.6999 17.211 61.0152 3.60412 60.9396L3.61343 59.2627ZM51.2539 81.4747L51.2533 138.112L49.5764 138.112L49.577 81.4747L51.2539 81.4747Z"
      fill="currentColor"
    />
    <line x1="50.5354" y1="96.3577" x2="50.5354" y2="0.00878917" stroke="currentColor" strokeWidth="5" />
  </svg>
);

export const ScrollDownIndicator = ({ rotate, text }: Props) => {
  return (
    <div className="relative w-full">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary p-1/10">
        <ArrowIcon />
      </div>
      <CircleText rotate={rotate} text={text} />
    </div>
  );
};
