import { Markdown } from "@mirrorprod/mexc";
import { FormattedMessage, useIntl } from "react-intl";
import { GridContainer } from "../../../layouts/grid_container";
import { FAQList } from "../faq_list";
import { motion } from "framer-motion";
import { staggeredAnimationProps, titlesAnimationProps } from "../../constants";

export const FAQs = () => {
  const intl = useIntl();
  return (
    <section id="faq">
      <div className="bg-primary text-secondary body2">
        <GridContainer>
          <motion.h1 {...titlesAnimationProps} className="col-start-1 col-span-full text-center">
            <FormattedMessage id="faq.title" />
          </motion.h1>
          <motion.div
            {...staggeredAnimationProps}
            viewport={{ once: true, amount: 0.2 }}
            className="col-start-1 col-span-full tablet:col-start-2 tablet:col-span-10"
          >
            <FAQList
              faqList={Array(10)
                .fill(" ")
                .map((a, idx) => ({
                  question: intl.formatMessage({ id: `faq.${idx + 1}.question` }),
                  answer: <Markdown>{intl.formatMessage({ id: `faq.${idx + 1}.answer` })}</Markdown>,
                }))}
            />
          </motion.div>
          <div className="col-start-1 col-span-full h4 text-center mt-xl">
            <FormattedMessage id="faq.otherQuestions1" />
          </div>
          <div className="col-start-1 col-span-full text-center mb-xl">
            <Markdown>{intl.formatMessage({ id: "faq.otherQuestions2" })}</Markdown>
          </div>
        </GridContainer>
      </div>
    </section>
  );
};
