import { SubColumns } from "../../../layouts/sub_columns";
import { motion } from "framer-motion";
import { staggeredRowAnimationProps } from "../../constants";

interface BenefitItem {
  title: React.ReactNode;
  description: React.ReactNode;
}

interface Props {
  benefitItems: BenefitItem[];
}

export const Benefits = ({ benefitItems }: Props) => {
  return (
    <div className="col-span-full gap-y-16 grid">
      {benefitItems.map((b, index) => (
        <motion.div {...staggeredRowAnimationProps} key={`benefits-row-${index}`}>
          <SubColumns className="grid-cols-6 tablet:grid-cols-10">
            <div className="col-span-1 flex justify-center items-center">
              <div className="w-4 h-4 tablet:w-6 tablet:h-6 rounded-full border border-accent"></div>
            </div>
            <div className="col-start-2 col-span-full">
              <h4>
                <em className="break-">{b.title}</em>
              </h4>
              <div>{b.description}</div>
            </div>
          </SubColumns>
        </motion.div>
      ))}
    </div>
  );
};
