interface Props {
  rotate?: boolean;
  text: React.ReactNode;
}

export const CircleText = ({ rotate, text }: Props) => {
  return (
    <svg
      style={{ animationDuration: "90s" }}
      viewBox="0 0 150 150"
      className={`${rotate ? "animate-spin" : ""} label text-[12.6px]`}
    >
      <defs>
        <path
          id="circle"
          d="
        M 75, 25
        a 50 50 0 0 1 0 100
        a 50 50 0 0 1 0 -100"
        />
      </defs>

      <text>
        <textPath className="fill-secondary" href="#circle">
          {text}
        </textPath>
      </text>
    </svg>
  );
};
