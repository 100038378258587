import Head from "next/head";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../common/components/atoms/button";
import { Roadmap } from "../common/components/molecules/roadmap";
import { ScrollDownIndicator } from "../common/components/molecules/scroll_down_indicator";
import { GridContainer } from "../common/layouts/grid_container";
import { Markdown } from "@mirrorprod/mexc";
import { useEffect, useState } from "react";
import { FAQs } from "../common/components/molecules/faqs";
import { Footer } from "../common/components/molecules/footer";
import { HeroSlideshow } from "../common/components/molecules/hero_slideshow";
import { NFTPanel } from "../common/components/molecules/nft_panel";
import { NFT } from "../common/model/nft";
import { GetServerSideProps } from "next";
import { getNFTsList } from "../common/api/content_loader";
import { Benefits } from "../common/components/molecules/benefits/benefits";
import { HeroSection } from "../common/components/sections/hero";
import { MotionConfig, motion } from "framer-motion";
import {
  flowersBagAnimationProps,
  staggeredAnimationProps,
  textsAnimationProps,
  titlesAnimationProps,
} from "../common/components/constants";
import { BenefitsSection } from "../common/components/sections/benefits/benefits";
import { RoadmapSection } from "../common/components/sections/roadmap/roadmap_section";
import { CollectionSection } from "../common/components/sections/collection_section";
import { ProjectSection } from "../common/components/sections/project_section";

interface Props {
  nftsList: NFT[];
}

const Home = ({ nftsList }: Props) => {
  const intl = useIntl();

  return (
    <>
      <Head>
        <title>Pinko Love bags</title>
        <meta name="description" content="Pinko Love bags" />
        <link rel="icon" href="/favicon.jpg" />
      </Head>

      <MotionConfig transition={{ type: "spring", stiffness: 200, damping: 40 }}>
        <main key="homepage" className="scroll-pt-32 tablet:scroll-pt-28">
          <HeroSection />
          <ProjectSection />
          <CollectionSection nftsList={nftsList} />
          <BenefitsSection key="home-benefits" />
          <RoadmapSection />
          <FAQs />
          <Footer />
        </main>
      </MotionConfig>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async () => {
  let nftsList: NFT[] = [];
  try {
    nftsList = await getNFTsList();
  } catch (err) {
    console.error(err);
  }

  return {
    props: {
      nftsList: nftsList,
    },
  };
};

export default Home;
