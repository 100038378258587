import { SubColumns } from "../../../layouts/sub_columns";
import { RoadmapItem } from "../roadmap_item";
import { motion } from "framer-motion";
import styles from "./index.module.css";
import { roadmapLineBagAnimationProps, staggeredAnimationProps, staggeredRowAnimationProps } from "../../constants";

interface RoadmapItem {
  day: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
}

interface Props {
  roadmapItems: RoadmapItem[];
}

const MileStonesLine = ({ milestonesCount }: { milestonesCount: number }) => {
  const milestoneDots = new Array(milestonesCount).fill("");
  const scaleBase = 1 / milestonesCount;
  return (
    <div className={`h-full w-6 relative flex flex-col justify-center ${styles.masked}`}>
      <div className="w-0 border-l border-accent h-full absolute top-0 left-1/2 transform -translate-x-1/2" />
      <div className="h-4/5 flex flex-col justify-between items-center">
        {milestoneDots.map((value, index) => (
          <div
            style={{ transform: `scale(${scaleBase * index + scaleBase * 3.5})` }}
            key={`roadmap-dot-${index}`}
            className="w-4 h-4 rounded-full border border-accent bg-secondary"
          />
        ))}
      </div>
    </div>
  );
};

export const Roadmap = ({ roadmapItems }: Props) => {
  return (
    <SubColumns className="grid-cols-6 tablet:grid-cols-9">
      <motion.div {...roadmapLineBagAnimationProps} className="col-start-1 col-span-1 flex flex-col items-center">
        <MileStonesLine milestonesCount={roadmapItems.length} />
      </motion.div>
      <motion.div {...staggeredAnimationProps} className="col-start-2 col-span-5 tablet:col-span-8">
        {roadmapItems.map((r, idx) => (
          <motion.div {...staggeredRowAnimationProps} key={`roadmap-item-${idx}`}>
            <RoadmapItem day={r.day} title={r.title} description={r.description} />
          </motion.div>
        ))}
      </motion.div>
    </SubColumns>
  );
};
