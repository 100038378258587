import { Markdown } from "@mirrorprod/mexc";
import { motion } from "framer-motion";
import { useIntl } from "react-intl";
import { GridContainer } from "../../../layouts/grid_container";
import { textsMountingAnimationProps } from "../../constants";

export const ProjectSection = () => {
  const intl = useIntl();
  return (
    <section id="project" className="mt-xl">
      <GridContainer>
        <motion.div
          {...textsMountingAnimationProps}
          className="text-center col-start-1 col-span-6 tablet:col-start-2 tablet:col-span-10"
        >
          <Markdown>{intl.formatMessage({ id: "project.description.text" })}</Markdown>
        </motion.div>
      </GridContainer>
    </section>
  );
};
