import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useState } from "react";

interface Props {
  images: string[];
  autoScroll?: boolean;
}

export const HeroSlideshow = ({ images, autoScroll }: Props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    const goToNextImage = () => {
      setCurrentImage((current) => (current + 1) % images.length);
      setFirstRender(false);
    };

    const intervalId = autoScroll && setInterval(goToNextImage, 5000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoScroll, images.length]);

  return (
    <div className="h-full w-full relative">
      <AnimatePresence>
        <motion.div
          className="absolute w-full h-full"
          initial={{ x: firstRender ? "0" : "100%" }}
          animate={{ x: "0" }}
          exit={{ x: "-100%" }}
          transition={{ ease: "easeInOut", duration: 1 }}
          key={`hero-image-container-${currentImage}`}
        >
          <Image
            priority
            key={`hero-image-${currentImage}`}
            src={images[currentImage]}
            alt="hero-image"
            layout="fill"
            objectFit="cover"
          />
        </motion.div>
      </AnimatePresence>
      <div className="w-full h-full absolute bg-black bg-opacity-10"></div>
    </div>
  );
};
