import Image from "next/image";
import Link from "next/link";
import { GridContainer } from "../../../layouts/grid_container";
import { FooterLinks } from "../footer_links";

export const Footer = () => {
  return (
    <>
      <GridContainer className="relative">
        <div className="col-start-1 col-span-full w-full my-xl">
          <img src="/images/footer/logo.svg" className="w-full" />
        </div>
      </GridContainer>
      <section className="bg-neutral-light py-base footer">
        <FooterLinks />
      </section>
    </>
  );
};
