interface Props {
  day: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
}

export const RoadmapItem = ({ day, title, description }: Props) => {
  return (
    <div>
      <h4>
        <span className="text-accent">{day}</span>, {title}
      </h4>
      <div className="p mt-1 mb-xs">{description}</div>
    </div>
  );
};
