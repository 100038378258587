import clsx from "clsx";
import { DetailedHTMLProps, HTMLAttributes } from "react";

// grid-cols-6 tablet:grid-cols-7
export const SubColumns = ({
  children,
  className,
  ...rest
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div
      className={clsx(
        className,
        "grid gap-x-layout-gutter-mobile tablet:gap-x-layout-gutter-tablet desktop:gap-x-layout-gutter-desktop"
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
