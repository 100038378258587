import { FormattedMessage, useIntl } from "react-intl";
import { GridContainer } from "../../../layouts/grid_container";
import { staggeredAnimationProps, textsAnimationProps, titlesAnimationProps } from "../../constants";
import { motion } from "framer-motion";
import { Benefits } from "../../molecules/benefits";

export const BenefitsSection = () => {
  const intl = useIntl();
  return (
    <section id="benefits">
      <GridContainer>
        <motion.h1 {...titlesAnimationProps} className="col-start-1 col-span-6 tablet:col-span-12 text-center">
          <FormattedMessage id="title.benefits.text" />
        </motion.h1>
        <motion.div
          {...textsAnimationProps}
          className="text-center col-start-1 col-span-6 tablet:col-start-2 tablet:col-span-10"
        >
          <FormattedMessage id="benefits.description" />
        </motion.div>
      </GridContainer>
      <GridContainer className="relative mt-xl body2">
        <motion.div
          {...staggeredAnimationProps}
          className="col-start-1 col-span-6 tablet:col-start-2 tablet:col-span-10"
        >
          <Benefits
            benefitItems={[
              {
                title: intl.formatMessage({ id: "benefits.1.title" }),
                description: intl.formatMessage({ id: "benefits.1.description" }),
              },
              {
                title: intl.formatMessage({ id: "benefits.2.title" }),
                description: intl.formatMessage({ id: "benefits.2.description" }),
              },
              {
                title: intl.formatMessage({ id: "benefits.3.title" }),
                description: intl.formatMessage({ id: "benefits.3.description" }),
              },
              {
                title: intl.formatMessage({ id: "benefits.4.title" }),
                description: intl.formatMessage({ id: "benefits.4.description" }),
              },
              {
                title: intl.formatMessage({ id: "benefits.5.title" }),
                description: intl.formatMessage({ id: "benefits.5.description" }),
              },
              {
                title: intl.formatMessage({ id: "benefits.6.title" }),
                description: intl.formatMessage({ id: "benefits.6.description" }),
              },
            ]}
          />
        </motion.div>
      </GridContainer>
    </section>
  );
};
