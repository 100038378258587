import { Markdown } from "@mirrorprod/mexc";
import { motion } from "framer-motion";
import { FormattedMessage, useIntl } from "react-intl";
import { GridContainer } from "../../../layouts/grid_container";
import { flowersBagAnimationProps, textsAnimationProps, titlesAnimationProps } from "../../constants";
import { Roadmap } from "../../molecules/roadmap";

export const RoadmapSection = () => {
  const intl = useIntl();
  return (
    <section id="roadmap">
      <GridContainer>
        <motion.h1 {...titlesAnimationProps} className="col-start-1 col-span-6 tablet:col-span-12 text-center">
          <FormattedMessage id="title.roadmap.text" />
        </motion.h1>
        <motion.div
          {...textsAnimationProps}
          className="text-center col-start-1 col-span-6 tablet:col-start-2 tablet:col-span-10"
        >
          <FormattedMessage id="roadmap.description" />
        </motion.div>
      </GridContainer>

      <GridContainer className="relative my-xl">
        <div className="col-start-1 col-span-6 tablet:col-span-9 body2">
          <Roadmap
            roadmapItems={[
              {
                day: <FormattedMessage id="roadmap.step1.day" />,
                title: <FormattedMessage id="roadmap.step1.title" />,
                description: <FormattedMessage id="roadmap.step1.description" />,
              },
              {
                day: <FormattedMessage id="roadmap.step2.day" />,
                title: <FormattedMessage id="roadmap.step2.title" />,
                description: <FormattedMessage id="roadmap.step2.description" />,
              },
              {
                day: <FormattedMessage id="roadmap.step3.day" />,
                title: <FormattedMessage id="roadmap.step3.title" />,
                description: <FormattedMessage id="roadmap.step3.description" />,
              },
              {
                day: <FormattedMessage id="roadmap.step4.day" />,
                title: <FormattedMessage id="roadmap.step4.title" />,
                description: <Markdown>{intl.formatMessage({ id: "roadmap.step4.description" })}</Markdown>,
              },
              {
                day: <FormattedMessage id="roadmap.step5.day" />,
                title: <FormattedMessage id="roadmap.step5.title" />,
                description: <FormattedMessage id="roadmap.step5.description" />,
              },
            ]}
          />
        </div>
        <motion.div
          {...flowersBagAnimationProps}
          className="hidden tablet:block absolute overflow-hidden -z-10 w-1/3 -mx-layout-margin-mobile tablet:-mx-layout-margin-tablet desktop:-mx-layout-margin-desktop right-0 flex flex-row justify-end top-1/2 transform -translate-y-1/2"
        >
          <img alt="background" src="/images/roadmap/bag.jpg" className="object-contain" />
        </motion.div>
      </GridContainer>
    </section>
  );
};
