import { motion } from "framer-motion";
import { FormattedMessage } from "react-intl";
import { GridContainer } from "../../../layouts/grid_container";
import { NFT } from "../../../model/nft";
import { titlesAnimationProps } from "../../constants";
import { NFTPanel } from "../../molecules/nft_panel";

export const CollectionSection = ({ nftsList }: { nftsList: NFT[] }) => {
  return (
    <section id="collection">
      <GridContainer>
        <motion.h1 {...titlesAnimationProps} className="col-start-1 col-span-6 tablet:col-span-12 text-center">
          <FormattedMessage id="title.collection.text" />
        </motion.h1>
        <div className="col-span-full">
          <NFTPanel
            detailsBaseURL="/details"
            nfts={nftsList.map((n) => ({ image: n.image, id: n.id, sold: n.sold }))}
          />
        </div>
      </GridContainer>
    </section>
  );
};
