import { AnimatePresence, motion } from "framer-motion";
import { SubColumns } from "../../../layouts/sub_columns";

interface Props {
  title: React.ReactNode;
  isOpen?: boolean;
  details: React.ReactNode;
  onItemClick?: () => void;
}

const CircleIcon = ({ checked }: { checked?: boolean }) => (
  <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16.54" cy="16.54" r="15.54" stroke="currentColor" strokeWidth="2" />
    {checked && <circle cx="16.5" cy="16.5" r="7.5" className="fill-accent" />}
  </svg>
);

export const FAQItem = ({ title, isOpen, details, onItemClick }: Props) => {
  return (
    <SubColumns
      onClick={onItemClick}
      className="block cursor-pointer grid-cols-6 tablet:grid-cols-10 border-t border-neutral-light text-secondary hover:text-accent px-layout-margin-mobile tablet:px-0"
    >
      <div
        className={`col-start-1 col-span-6 tablet:col-start-1 tablet:col-span-6 ${
          isOpen ? "text-accent" : ""
        } transition-colors py-3`}
      >
        <h4 className="text-center tablet:text-left">{title}</h4>
      </div>
      <div className="hidden tablet:col-start-10 text-neutral-light tablet:flex tablet:flex-row tablet:items-center tablet:justify-center ">
        <div className="tablet:w-[15px] desktop:w-[25px]">
          <CircleIcon checked={isOpen} />
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            layout
            initial={{ opacity: 0, height: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ type: "spring", duration: 0.8, bounce: 0.5 }}
            className="text-secondary col-start-1 col-span-6 tablet:col-start-1 tablet:col-span-6 overflow-y-hidden"
          >
            <div className="my-base">{details}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </SubColumns>
  );
};
