import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { NFT } from "../../../model/nft";
import { Button } from "../../atoms/button";

export const NFTBox = ({ image, url, sold }: { image: string; url: string; sold?: boolean }) => {
  const [mouseHover, setMouseHover] = useState(false);
  return (
    <Link prefetch href={url}>
      <a>
        <div
          className="cursor-pointer hidden tablet:block w-full aspect-w-1 aspect-h-1 relative"
          onMouseEnter={() => setMouseHover(true)}
          onMouseLeave={() => setMouseHover(false)}
        >
          <Image src={image} alt="nft-image" layout="fill" objectFit="cover" />
          {sold ? (
            <div className="w-full h-full absolute bg-black bg-opacity-40 flex justify-center items-center">
              <div className="py-2 px-8 rounded-full bg-primary text-secondary">
                <h6 className="font-bold">
                  <FormattedMessage id="nftPanel.soldLabel" />
                </h6>
              </div>
            </div>
          ) : (
            <AnimatePresence>
              {mouseHover && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="w-full h-full absolute bg-black bg-opacity-80 flex justify-center items-center"
                >
                  <motion.div initial={{ y: "100%" }} animate={{ y: "0%" }} exit={{ y: "100%" }}>
                    <Button label={<FormattedMessage id="nftPanel.buyCTA.label" />} />
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </div>

        <div className="block tablet:hidden">
          <div className="w-full aspect-w-1 aspect-h-1 relative">
            <Image src={image} alt="nft-image" layout="fill" objectFit="cover" />
          </div>

          <div className={`h-10 ${sold ? "bg-primary" : "bg-accent"} w-full flex justify-center items-center`}>
            <h4 className={`text-secondary`}>
              {sold ? <FormattedMessage id="nftPanel.soldLabel" /> : <FormattedMessage id="nftPanel.buyCTA.label" />}
            </h4>
          </div>
        </div>
      </a>
    </Link>
  );
};
