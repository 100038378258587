import { NFTBox } from "./nft_box";
import { motion } from "framer-motion";
import { staggeredAnimationProps, staggeredGridCellAnimationProps } from "../../constants";

interface Props {
  detailsBaseURL: string;
  nfts: {
    id: string;
    image: string;
    sold?: boolean;
  }[];
}

export const NFTPanel = ({ nfts, detailsBaseURL }: Props) => {
  return (
    <motion.div {...staggeredAnimationProps} className="w-full grid grid-cols-2 tablet:grid-cols-5 gap-5 tablet:gap-0">
      {nfts.map((nft) => (
        <motion.div
          style={{ originX: 0.5 }}
          {...staggeredGridCellAnimationProps}
          transition={{ type: "spring", stiffness: 800, damping: 60 }}
          key={`nft-panel-${nft.id}`}
          className="col-span-1"
        >
          <NFTBox sold={nft.sold} image={nft.image} url={`${detailsBaseURL}/${nft.id}`} />
        </motion.div>
      ))}
    </motion.div>
  );
};
