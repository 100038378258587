import { FormattedMessage } from "react-intl";
import { GridContainer } from "../../../layouts/grid_container";
import { HeroSlideshow } from "../../molecules/hero_slideshow";
import { ScrollDownIndicator } from "../../molecules/scroll_down_indicator";
import { motion } from "framer-motion";
import { useState } from "react";

export const HeroSection = () => {
  const [autoScroll, setAutoScroll] = useState(false);

  return (
    <section id="hero" className="overflow-hidden">
      <GridContainer
        style={{ gridTemplateRows: "1fr 2fr 1fr" }}
        className="text-secondary h-[100vw] tablet:h-screen relative"
      >
        <motion.div
          initial={{ scale: 2, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          onAnimationComplete={() => setAutoScroll(true)}
          className="col-span-full absolute w-screen h-full tablet:h-screen overflow-hidden -z-10 -mx-layout-margin-mobile tablet:-mx-layout-margin-tablet desktop:-mx-layout-margin-desktop"
        >
          <HeroSlideshow
            autoScroll={autoScroll}
            images={[
              "/images/hero/bondage.jpg",
              "/images/hero/butterflies.jpg",
              "/images/hero/flowers.jpg",
              "/images/hero/ice_pop.jpg",
              "/images/hero/music_tape.jpg",
            ]}
          />
        </motion.div>
        <div className="col-span-full"></div>
        <motion.h1
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          className="col-start-1 col-span-3 tablet:col-span-5 flex items-center tablet:justify-end my-0"
        >
          Love
        </motion.h1>
        <motion.h1
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          className="col-start-4 col-span-3 tablet:col-start-8 tablet:col-span-5 flex flex-col justify-center tablet:items-start text-right my-0"
        >
          Bags
        </motion.h1>
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          className="hidden col-start-1 col-span-2 tablet:flex items-center justify-center"
        >
          <div className="w-[65%]">
            <ScrollDownIndicator text={<FormattedMessage id="scrollDownIndicator.label" />} rotate />
          </div>
        </motion.div>
      </GridContainer>
    </section>
  );
};
