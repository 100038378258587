import { motion } from "framer-motion";
import { useState } from "react";
import { staggeredRowAnimationProps } from "../../constants";
import { FAQItem } from "../faq_item";

interface FAQData {
  question: React.ReactNode;
  answer: React.ReactNode;
}

interface Props {
  faqList: FAQData[];
}

export const FAQList = ({ faqList }: Props) => {
  const [currentOpenedFAQ, setCurrentOpenedFAQ] = useState<number | undefined>();

  return (
    <div className="">
      {/* <AnimatePresence> */}
      {faqList.map((faq, idx) => (
        <motion.div key={`faq-list-item-${idx}`} {...staggeredRowAnimationProps}>
          <FAQItem
            details={faq.answer}
            title={faq.question}
            isOpen={currentOpenedFAQ === idx}
            onItemClick={() => {
              if (currentOpenedFAQ === idx) {
                setCurrentOpenedFAQ(undefined);
              } else {
                setCurrentOpenedFAQ(idx);
              }
            }}
          />
        </motion.div>
      ))}
      {/* </AnimatePresence> */}
    </div>
  );
};
