const textVariants = { initial: { opacity: 0, translateY: "70%" }, animate: { opacity: 1, translateY: 0 } };
const titleVariants = { initial: { opacity: 0, x: "50%" }, animate: { opacity: 1, x: 0 } };
const staggeredContainerVariants = {
  initial: { opacity: 1 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};
const staggeredVariants = {
  initial: { opacity: 0, translateY: 200 },
  animate: {
    opacity: 1,
    translateY: 0,
  },
};

const staggeredGridVariants = {
  initial: { opacity: 0, scale: 1.5 },
  animate: {
    opacity: 1,
    scale: 1,
  },
};

const flowersBagVariants = {
  initial: { opacity: 0, x: "100%", translateY: "-50%" },
  animate: { opacity: 1, x: 0, translateY: "-50%" },
};

const roadmapLineBagVariants = {
  initial: { opacity: 0, x: "-100%" },
  animate: { opacity: 1, x: 0 },
};

export const titlesAnimationProps = {
  variants: titleVariants,
  initial: "initial",
  whileInView: "animate",
  viewport: { once: true },
};

export const textsAnimationProps = {
  variants: textVariants,
  initial: "initial",
  whileInView: "animate",
  viewport: { once: true },
};

export const titleMountingAnimationProps = {
  variants: titleVariants,
  initial: "initial",
  animate: "animate",
};

export const textsMountingAnimationProps = {
  variants: textVariants,
  initial: "initial",
  animate: "animate",
};

export const staggeredAnimationProps = {
  variants: staggeredContainerVariants,
  initial: "initial",
  whileInView: "animate",
  viewport: { once: true },
};

export const staggeredRowAnimationProps = {
  variants: staggeredVariants,
};

export const staggeredGridCellAnimationProps = {
  variants: staggeredGridVariants,
};

export const flowersBagAnimationProps = {
  variants: flowersBagVariants,
  initial: "initial",
  whileInView: "animate",
  viewport: { once: true },
};

export const roadmapLineBagAnimationProps = {
  variants: roadmapLineBagVariants,
  initial: "initial",
  whileInView: "animate",
  viewport: { once: true },
};
